.footer-container {
    position: relative;
}

.footer-container>hr {
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    height: 20rem;
}

.social-links {
    display: flex;
    gap: 4rem;
}

.social-links>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img {
    width: 10rem;
}

.blur-f1 {
    width: 38rem;
    height: 12rem;
    bottom: 0;
    right: 15%;
    filter: blur(200px);
    background: red;
}

.blur-f2 {
    width: 38rem;
    height: 12rem;
    bottom: 0;
    left: 15%;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}